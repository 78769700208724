.card-animation {
    opacity: 0;
    transition: opacity 0.5s ease-in;
}

.card-animation.visible {
    opacity: 1;
}

.break-word {
    word-break: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

/* src/styles/fonts.css */
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap');

.ttt {
    font-family: 'Caveat', cursive;
}